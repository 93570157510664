import axios from "axios";
import config from ".";

const headers = { "Content-Type": "application/json" };

// Clients
export const getCustomer = async (e, accessToken) => {
  const request = await axios({
    url:
      config.API_URL +
      "/customer/" +
      e +
      "?business=express&include=connections",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getAllCustomers = async ({ accessToken, skip, take, filter }) => {
  const query = filter ? `&locationId=${filter}` : "";
  const request = await axios({
    url: `${config.API_URL}/customer/search?skip=${skip}&take=${take}${query}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const searchCustomers = async ({ accessToken, skip, take, query }) => {
  const request = await axios({
    url: `${config.API_URL}/customer/search?skip=${skip}&take=${take}&q=${query}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getAllCustomerAttentions = async ({
  filter,
  accessToken,
  skip,
  take,
}) => {
  const query = filter === "none" ? "active=true" : `${filter}=true`;
  const request = await axios({
    url: `${config.API_URL}/queue/attention/search?${query}&skip=${skip}&take=${take}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const searchCustomerAttentions = async ({
  accessToken,
  skip,
  take,
  query,
}) => {
  const request = await axios({
    url: `${config.API_URL}/queue/attention/search?skip=${skip}&take=${take}&q=${query}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getCustomerAttentions = async (id, accessToken) => {
  const request = await axios({
    url: config.API_URL + "/queue/attention/search?customerId=" + id,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getSessionAttentions = async (id, accessToken) => {
  const request = await axios({
    url: config.API_URL + "/queue/attention/search?sessionId=" + id,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getAttention = async (id, accessToken) => {
  const request = await axios({
    url: `${config.API_URL}/queue/attention/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getSession = async (id, accessToken) => {
  const request = await axios({
    url: `${config.API_URL}/session/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getCustomerSessions = async (id, accessToken) => {
  const request = await axios({
    url: `${config.API_URL}/session/user/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const transferSession = async ({ sessionId, queueId, accessToken }) => {
  const request = await axios({
    url: `${config.API_URL}/session/${sessionId}/transfer`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    data: {
      queueId,
    },
    method: "POST",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

// Stats
export const getDashboardStats = async (accessToken) => {
  const request = await axios({
    url: `${config.API_URL}/stats/dashboard`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getNPSStats = async (accessToken, { from, to }) => {
  const request = await axios({
    url: `${config.API_URL}/stats/nps${
      from && to
        ? `?startDate=${from.toISOString()}&endDate=${to.toISOString()}`
        : ""
    }`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getNPSEvaluationStats = async (accessToken, { from, to }) => {
  const bmVariable = "contactoevaluacion";
  const request = await axios({
    url: `${config.API_URL}/stats/variable/${bmVariable}/values-count${
      from && to
        ? `?startDate=${from.toISOString()}&endDate=${to.toISOString()}`
        : ""
    }`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getNPSCountPerDay = async (accessToken, { from, to }) => {
  const request = await axios({
    url: `${config.API_URL}/stats/nps/evolution${
      from && to
        ? `?startDate=${from.toISOString()}&endDate=${to.toISOString()}`
        : ""
    }`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const getNPSMotives = async (accessToken, { from, to }) => {
  const positiveConfig = {
    variable: "positivo",
    possible: [
      { name: "rap", value: "Rápida atención" },
      { name: "res", value: "Resolvieron mi duda" },
      { name: "ama", value: "El agente fue amable" },
    ],
  };
  const negativeConfig = {
    variable: "negativo",
    possible: [
      { name: "rap", value: "Tardaron mucho" },
      { name: "res", value: "No me resolvieron" },
      { name: "ama", value: "No fue amable" },
    ],
  };

  const positive = await axios({
    url: `${config.API_URL}/stats/nps/motives/${
      positiveConfig.variable
    }?possibleValues=${JSON.stringify(positiveConfig.possible)}${
      from && to
        ? `&startDate=${from.toISOString()}&endDate=${to.toISOString()}`
        : ""
    }`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  const negative = await axios({
    url: `${config.API_URL}/stats/nps/motives/${
      negativeConfig.variable
    }?possibleValues=${JSON.stringify(negativeConfig.possible)}${
      from && to
        ? `&startDate=${from.toISOString()}&endDate=${to.toISOString()}`
        : ""
    }`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });

  return {
    positive: positive.data,
    negative: negative.data,
  };
};

// Agents
export const getAgents = async (accessToken) => {
  const request = await axios({
    url: `${config.API_URL}/agent/search`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

// Users
export const getAllUsers = async (accessToken) => {
  const request = await axios({
    url: `${config.API_URL}/user/search`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    method: "GET",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

export const createUser = async ({
  firstName,
  lastName,
  email,
  role,
  queues,
  botmaker,
  botadmin,
  accessToken,
}) => {
  const request = await axios({
    url: `${config.API_URL}/agent`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    data: {
      firstName,
      lastName,
      email,
      role,
      queues,
      botmaker,
      botadmin,
    },
    method: "POST",
  }).catch((error) => {
    throw new Error(error);
  });
  return request.data;
};

/**
 * Old services
 * @all
 * @deprecated
 **/

// ______________________________________________________________

// Tickets
export const getAllTickets = async () => {
  const request = await fetch(config.API_URL + "/tickets/all").catch(
    (error) => {
      throw new Error(error);
    }
  );
  return request.json();
};

export const getAllTicketsByClient = async (id) => {
  const request = await fetch(config.API_URL + "/tickets/client/" + id).catch(
    (error) => {
      throw new Error(error);
    }
  );
  return request.json();
};

export const getTicketById = async (id) => {
  const request = await fetch(config.API_URL + "/ticket/" + id).catch(
    (error) => {
      throw new Error(error);
    }
  );
  return request.json();
};

export const createTicket = async (e) => {
  console.log(e);
  const reqData = {
    method: "POST",
    headers,
    // mode: 'no-cors',
    body: JSON.stringify(e),
  };
  const request = await fetch(config.API_URL + "/ticket", reqData).catch(
    (error) => {
      throw new Error(error);
    }
  );
  return request.json();
};

export const updateTicket = async (body) => {
  const reqData = {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  };
  const request = await fetch(
    config.API_URL + "/tickets/" + body._id,
    reqData
  ).catch((error) => {
    throw new Error(error);
  });
  console.log(request, body);
  return request.json();
};

// Caja
export const getAllMoneyMovements = async () => {
  const request = await fetch(config.API_URL + "/caja").catch((error) => {
    throw new Error(error);
  });
  return request.json();
};

export const createMoneyMovement = async (e) => {
  const reqData = {
    method: "POST",
    headers,
    body: JSON.stringify({
      concepto: e.concepto,
      fecha: e.fecha,
      monto: e.monto,
      responsable: e.responsable,
      tipo: e.tipo,
    }),
  };
  const request = await fetch(config.API_URL + "/caja", reqData).catch(
    (error) => {
      throw new Error(error);
    }
  );
  return request.json();
};

export const deleteMoneyMovement = async (body) => {
  const reqData = {
    method: "PUT",
    headers,
  };
  const request = await fetch(
    config.API_URL + "/caja/" + body._id,
    reqData
  ).catch((error) => {
    throw new Error(error);
  });
  return request.json();
};
