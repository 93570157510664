import "../../css/Stats.css";
import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import {
  getNPSStats,
  getNPSEvaluationStats,
  getNPSMotives,
  getNPSCountPerDay,
} from "../../config/api";
import { useAuth0 } from "@auth0/auth0-react";
import ChartPie from "../../Components/PieChart";
import StatCard from "../../Components/StatCard";
import DatePicker from "../../Components/ui/DataPicker";
import moment from "moment";
import { Search } from "lucide-react";
import BarChart from "../../Components/BarChart";
import { Gauge } from "../../Components/Gauge";

function Stats(props) {
  const endOfMonth = moment().date(1).add(1, "month").subtract(1, "day");
  const now = moment().add(1, "day");
  const [stats, setStats] = useState({
    NPS: {
      score: 0,
      average: 0,
      count: 0,
      detractors: 0,
      neutrals: 0,
      promoters: 0,
      perDay: [],
    },
    motives: {
      positive: [],
      negative: [],
    },
    evals: [],
    date: {
      from: moment().date(1).toDate(),
      to: endOfMonth.isAfter(now) ? now.toDate() : endOfMonth.toDate(),
    },
  });
  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => await getAccessTokenSilently();

  async function getDashboardItems() {
    try {
      await getToken().then((accessToken) => {
        setStats((s) => ({
          ...s,
          NPS: {
            score: 0,
            average: 0,
            count: 0,
            detractors: 0,
            neutrals: 0,
            promoters: 0,
            perDay: [],
          },
          motives: {
            positive: [],
            negative: [],
          },
          evals: [],
        }));
        Promise.all([
          getNPSEvaluationStats(accessToken, stats.date)
            .then((data) => {
              setStats((s) => ({
                ...s,
                evals: data.map((v, i) => ({
                  name: v.value,
                  value: v.count,
                  color: COLORS[i % COLORS.length],
                })),
              }));
              return data;
            })
            .catch((e) => console.error("[getNPSEvaluationStats] Error: " + e)),
          getNPSStats(accessToken, stats.date)
            .then((data) => {
              setStats((s) => ({
                ...s,
                NPS: {
                  score: data.NPS,
                  average: data.average,
                  count: data.count,
                  detractors: data.detractors.count,
                  neutrals: data.neutrals.count,
                  promoters: data.promoters.count,
                },
              }));
              getNPSCountPerDay(accessToken, stats.date)
                .then((data) => {
                  setStats((s) => ({
                    ...s,
                    NPS: {
                      ...s.NPS,
                      perDay: data,
                    },
                  }));
                  return data;
                })
                .catch((e) =>
                  console.error("[getNPSEvaluationStats] Error: " + e)
                );
            })
            .catch((e) => console.error("[getNPSAverageStats] Error: " + e)),
          getNPSMotives(accessToken, stats.date)
            .then((data) => {
              setStats((s) => ({
                ...s,
                motives: {
                  positive: data.positive.map((v, i) => ({
                    name: v.value,
                    value: v.count,
                    color: COLORS[i % COLORS.length],
                  })),
                  negative: data.negative.map((v, i) => ({
                    name: v.value,
                    value: v.count,
                    color: COLORS[i % COLORS.length],
                  })),
                },
              }));
              return data;
            })
            .catch((e) => console.error("[getNPSEvaluationStats] Error: " + e)),
        ]);
      });
    } catch (error) {
      console.error("[getDashboardItems] Error: " + error);
    }
  }

  useEffect(() => {
    getDashboardItems();
  }, []);

  const COLORS = ["#0088FE", "#ffb617", "#FF8042", "#00C49F", "#8884d8"];

  return (
    <>
      <Navbar />
      <main>
        <div className="page-header">
          <h1 className="page-title">NPS</h1>
          <DatePicker
            date={stats.date}
            className="ml-auto"
            setDate={(date) => setStats((s) => ({ ...s, date }))}
          />
          <button
            className="ml-1.5 bg-transparent hover:bg-grey-800 border-gray-400/70"
            onClick={() => getDashboardItems()}
          >
            <Search
              className="h-5 w-5 mt-px mr-0.5 text-gray-400"
              fill="lightgray"
            />
          </button>
        </div>
        <hr />
        <div className="stats-container">
          <StatCard title="NPS">
            <div style={{ margin: "auto", textAlign: "center" }}>
              <Gauge value={stats.NPS.score} />
              <h1 className="text-7xl mt-0 mb-0">
                {stats.NPS ? Number(stats.NPS.score) : "-"}
              </h1>
              <h4 className="mt-0">Score</h4>
            </div>
            <div className="mx-auto mt-2 text-center -mb-2">
              <p className="text-xs mb-0 text-gray-500 font-semibold">
                Promedio{" "}
                <span className="text-black ml-1">
                  {(stats.NPS.average ?? 0).toFixed(1)}
                </span>
              </p>
              <p className="text-xs mt-1 text-gray-500 font-semibold">
                Cantidad{" "}
                <span className="text-black ml-1">{stats.NPS.count ?? 0}</span>
              </p>
            </div>
          </StatCard>
          <StatCard title="Composición NPS">
            <ChartPie
              hollow
              width={240}
              height={320}
              data={[
                {
                  name: "Promotores",
                  value: stats.NPS.promoters,
                  color: "#0088FE",
                },
                {
                  name: "Neutrales",
                  value: stats.NPS.neutrals,
                  color: "#ffb617",
                },
                {
                  name: "Detractores",
                  value: stats.NPS.detractors,
                  color: "#FF8042",
                },
              ]}
            />
          </StatCard>
          <StatCard title="Evolución de respuestas">
            <div className="w-[570px] -mx-7 -mb-4 h-[300px]">
              <BarChart
                data={stats.NPS.perDay?.map((v) => ({
                  date: moment(v.date.value).format("DD-MM"),
                  count: v.count,
                  name: "Cantidad",
                }))}
              />
            </div>
          </StatCard>
        </div>

        <div className="stats-container">
          <StatCard title="Evaluación de atención">
            <ChartPie data={stats.evals} hollow={false} />
          </StatCard>
          <StatCard title="Buena experiencia">
            <ChartPie data={stats.motives.positive} hollow={false} />
          </StatCard>
          <StatCard title="Mala experiencia">
            <ChartPie data={stats.motives.negative} hollow={false} />
          </StatCard>
        </div>
        <div className="stats-table"></div>
      </main>
    </>
  );
}

export default Stats;
