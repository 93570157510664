import moment from "moment";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatDate = (date) => {
  const newDate = new Date(date ?? "").toLocaleDateString("es-ES");
  if (String(newDate) === "Invalid Date") {
    const splitDate = date.split("-");
    return new Date(
      `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
    ).toLocaleDateString("es-ES");
  }
  return newDate;
};

export const compareObjects = (object1, object2) => {
  // Deep nested comparison
  if (object1 === (null || undefined) || object2 === (null || undefined))
    return false;
  const isObject = (object) => {
    return object != null && typeof object === "object";
  };

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (let key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !compareObjects(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const parseTime = (time, finish) => {
  const startTime = time !== null ? moment(time) : moment();
  const endTime = finish !== null ? moment(finish) : moment();
  const diffTime = moment(endTime).diff(startTime);
  const waitingTime = moment.duration(diffTime);

  const hours = waitingTime.hours();
  const minutes = waitingTime.minutes();
  const seconds = waitingTime.seconds();
  return `${hours ? hours + ":" : ""}${
    minutes ? (minutes < 10 ? "0" + minutes : minutes) : "00"
  }:${seconds < 10 ? "0" + seconds : seconds}`;
};

export const parseDni = (dni) => {
  if (dni.length > 9) {
    // CUIT
    const identificador = dni.slice(0, 2);
    const medio = dni.slice(2, -1);
    const final = dni.slice(-1);
    return `${identificador}-${medio}-${final}`;
  }
  const mil = dni.slice(-3);
  const cienmil = dni.slice(-6, -3);
  const millon = dni.slice(-9, -6);
  return millon
    ? `${millon}.${cienmil}.${mil}`
    : cienmil
    ? `${cienmil}.${mil}`
    : `${mil}`;
};

export const lapsedMinutes = (time) => {
  const now = moment();
  const start = moment(time);
  const duration = moment.duration(now.diff(start)).asMinutes();
  return duration;
};

export const parseStatus = (status) => {
  const attentionStatus = {
    IN_PROCESS: "Asignado",
    WAITING: "Esperando",
    FINISHED: "Finalizada",
    TRANSFERED: "Transferido",
    CANCELED: "Cancelado",
  };
  return attentionStatus[status];
};

export const capitalizeFirstLetter = (word) => {
  const firstLetter = word.charAt(0).toUpperCase();
  return firstLetter + word.slice(1);
};

export const queues = [
  {
    id: "clcvdj8d70002jxv8exkp4i89",
    name: "Soporte Web",
    botmakerId: "soporteweb",
  },
  {
    id: "cle3kg7xt00003b6k44po7jur",
    name: "Soporte Ventas",
    botmakerId: "soporteventas",
  },
  {
    id: "cle3kg7xt00013b6kxrp09iqg",
    name: "Soporte Redes",
    botmakerId: "soporteredes",
  },
  {
    id: "cle3kg7xt00023b6kv9x3pwtr",
    name: "Retención",
    botmakerId: "Retencion",
  },
  {
    id: "cle3kg7xt00033b6ku2du66ux",
    name: "Ecorp",
    botmakerId: "clientesconcuit",
  },
  {
    id: "cle3kg7xt00043b6knh83kepu",
    name: "Administración",
    botmakerId: "ADMINISTRACIÓN",
  },
];

export const locations = [
  {
    id: "clcvdj8d70004jxv8agrz6lbx",
    name: "Rosario",
  },
  {
    id: "clcvdj8d70007jxv86rj82t9s",
    name: "Salta",
  },
  {
    id: "clcvdj8d70008jxv8fyyv97zs",
    name: "Santiago del Estero",
  },
  {
    id: "clcwbnspd0000eqv8hiixa6b7",
    name: "San Lorenzo",
  },
  {
    id: "clcwbnspe0001eqv8fdfl6mxs",
    name: "San Nicolás",
  },
];
